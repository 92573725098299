import React, { useEffect, useState, createContext } from 'react';
import Header from '../components/Header';
import Home from './Home';
import { Routes, Route, HashRouter, useLocation, BrowserRouter } from 'react-router-dom';
import '../css/header.css';
import '../css/style.css';
import '../css/dashboard.css';
import '../css/home.css';
import '../css/side-bar.css';
import '../css/profile.css';
import '../css/badge.css';
import '../css/projects.css';
import '../css/leaderboard.css';
import '../css/contact-us.css';
import Leaderboard from './Leaderboard';
import ContactUs from './ContactUs';
import Profile from './Profile';
import Dashboard from './Dashboard';
import Sidebar from '../components/Sidebar';
import StarsCanvas from '../assets/Stars';
import Particle from '../assets/Particle';
import Footer from '../components/Footer'
import RequireAuth from '../assets/RequireAuth';
import CryptoJS from 'crypto-js'
import AllBadges from './AllBadges';
import BadgePage from '../components/BadgePage';
import { addDoc, collection, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Projects from './Projects';
import ProjectDetail from '../components/ProjectDetail';
import AddProjectForm from '../components/AddProjectForm';

export const MyContext = createContext()

const Main = () => {

  const [isAside, setIsAside] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [repoData, setRepoData] = useState([]);
  const [prData, setPrData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [email, setEmail] = useState(null);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [badgeData, setBadgeData] = useState([]);
  const [leaderboardLoading, setLeaderboardLoading] = useState(false);

  const Encrypted = (data) => {
    const enc = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString()
    return enc;
  }

  const Decrypted = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
    const dec = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return dec;
  }

  useEffect(() => {
    if (email === null || userData === null) {
      setIsAuth(false)
    }
    if (isAuth) {
      getAllData()
      fetchLeaderboardData();
    }
  }, [isAuth])

  useEffect(() => {
    try {
      const enc_email = localStorage.getItem('e');
      const curr_email = Decrypted(enc_email);
      if (curr_email) {
        setEmail(curr_email);
      }
      const enc_username = localStorage.getItem('u');
      if (enc_username) {
        const curr_username = Decrypted(enc_username)
        if (curr_username) {
          setUserData(curr_username);
          setIsAuth(true)
        }
      }
    } catch (error) {
      console.log(error.message);
    }
    const badgeDataRef = collection(db, 'userbadges');
    const projectCollectionRef = collection(db, 'projectIdeas');

    const projectQuery = query(projectCollectionRef, where('ok', '==', 1));

    const unsubscribeProject = onSnapshot(projectQuery, (snapshot) => {
      const projects = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProjectData(projects);
    });

    const unsubscribeBadge = onSnapshot(badgeDataRef, (snapshot) => {
      const userBadgeData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setBadgeData(userBadgeData);
    });


    return () => {
      unsubscribeBadge();
      unsubscribeProject();
    }

  }, []);

  useEffect(() => {
    if (email !== null) {
      const enc_email = Encrypted(email);
      localStorage.setItem('e', enc_email);
    }

    if (userData !== null) {
      const enc_username = Encrypted(userData);
      localStorage.setItem('u', enc_username);
    }
  }, [email, userData])

  const fetchLeaderboardData = () => {
    setLeaderboardLoading(true)
    const leaderboardRef = collection(db, 'soc-users');
    const unsubscribe = onSnapshot(leaderboardRef, (snapshot) => {
      const pointsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const documentsWithPoints = pointsData.filter(doc => doc.points !== undefined);
      const sortedDocuments = documentsWithPoints.sort((a, b) => b.points - a.points);
      const documentsWithRank = sortedDocuments.map((doc, index) => ({
        ...doc,
        rank: index + 1
      }));

      setLeaderboardData(documentsWithRank);
    });
    setLeaderboardLoading(false);

    return () => unsubscribe();
  };

  const getAllData = () => {
    setIsLoading(true)

    const repoCollectionRef = collection(db, 'repository-data');
    const prCollectionRef = collection(db, 'pullRequests');

    const unsubscribeRepo = onSnapshot(repoCollectionRef, (snapshot) => {
      const repositoryData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setRepoData(repositoryData);
    });

    const unsubscribePr = onSnapshot(prCollectionRef, async (snapshot) => {
      const pullRequestData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const updatedPrData = await Promise.all(pullRequestData.map(async (pr) => {
        const userQuery = query(collection(db, 'soc-users'), where('username', '==', pr.requesterName));
        const userSnapshot = await getDocs(userQuery);

        if (!userSnapshot.empty) {
          const userData = userSnapshot.docs[0].data();
          return {
            ...pr,
            email: userData.email
          };
        } else {
          return {
            ...pr,
            email: null // or handle the case where the email is not found
          };
        }
      }));
      setPrData(updatedPrData);
    });
    setIsLoading(false)

    return () => {
      unsubscribeRepo();
      unsubscribePr();
    };
  };

  const addProjectIdea = async (data) => {
    await addDoc(collection(db, 'projectIdeas'), { ...data, user: userData });
  }

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  const value = {
    repoData, setRepoData, prData, setPrData, isLoading, isAuth,
    setIsAuth, userData, setUserData, email, setEmail, Encrypted, Decrypted, badgeData,
    leaderboardData, projectData, addProjectIdea
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <MyContext.Provider value={value}>
        <Header isAside={isAside} setIsAside={setIsAside} />
        <Sidebar isAside={isAside} setIsAside={setIsAside} />
        <div className={'main ' + (isAside && 'blur')}>
          <StarsCanvas />
          <Particle />
          <Routes>
            <Route path='/' element={<Home isAuth={isAuth} />} />
            <Route path='/dashboard' >
              <Route index element={<RequireAuth><Dashboard filter='repo' /></RequireAuth>} />
              <Route path='user' element={<RequireAuth><Dashboard filter='user' /></RequireAuth>} />
            </Route>
            <Route path='/dashboard/:repoName' element={<RequireAuth><Dashboard filter='repo' /></RequireAuth>} />
            <Route path='/dashboard/user/:userName' element={<RequireAuth><Dashboard filter='user' /></RequireAuth>} />
            <Route path='/leaderboard' element={<RequireAuth><Leaderboard leaderboardData={leaderboardData} username={userData} leaderboardLoading={leaderboardLoading} /></RequireAuth>} />
            <Route path='/profile/:userName' element={<RequireAuth><Profile /></RequireAuth>} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/all-badges' element={<AllBadges />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/projects/:id' element={<ProjectDetail />} />
            <Route path='/projects/add-project' element={<AddProjectForm />} />
            <Route path='/soc-view-badge/:id' element={<BadgePage />} />
            <Route path='*' element={<h1 style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Sorry Sapiens, There's no content ahead🙌</h1>} />
          </Routes>
        </div>
        <Footer />
      </MyContext.Provider>
    </BrowserRouter>
  );
}

export default Main;
