import React, { useContext } from 'react';
import { MyContext } from '../pages/Main';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { auth, db, githubProvider } from '../firebaseConfig';
import { GithubAuthProvider, signInWithPopup } from 'firebase/auth';
import { collection, getDocs, query, updateDoc, where } from 'firebase/firestore';

const GithubLogin = () => {
  const { setUserData, setIsAuth, email } = useContext(MyContext);

  const signInWithGitHub = async () => {
    try {
      const result = await signInWithPopup(auth, githubProvider);
      console.log(result);
      const credential = GithubAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      // Fetch GitHub username using the access token
      const response = await fetch('https://api.github.com/user', {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const githubUser = await response.json();
      console.log(githubUser);

      const authorizedUser = await getDocs(query(collection(db, 'soc-users'), where('email', '==', email)));
      const githubAuthorizedUser = await getDocs(query(collection(db, 'soc-users'), where('username', '==', githubUser.login)));
      if (!authorizedUser.empty && githubAuthorizedUser.empty) {
        const docRef = authorizedUser.docs[0].ref;
        await updateDoc(docRef, { username: githubUser.login });
        setUserData(githubUser.login);
        setIsAuth(true);
        Swal.fire({
          title: 'Hey There',
          text: 'Shall we Rock?',
          icon: 'success',
          confirmButtonText: 'Yeah!'
        });
      } else if (authorizedUser.empty) {
        Swal.fire({
          title: 'Email Not Found',
          text: 'Login with email first',
          icon: 'error',
        });
      } else if (!githubAuthorizedUser.empty) {
        Swal.fire({
          title: 'Github user exists',
          text: 'Already a user logged in with this github account',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('GitHub sign-in error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong with GitHub sign-in.',
        confirmButtonText: 'Ok'
      });
    }
  };

  return (
    <div className='github-btn' onClick={() => signInWithGitHub(email)}>
      <FontAwesomeIcon icon={faGithub} />&nbsp;Sign in via Github
    </div>
  );
}

export default GithubLogin;
