import { faCheckCircle, faClock, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'

const UserCard = (props) => {


    const formatDateString = (dateString) => {
        const date = new Date(dateString.seconds * 1000 + dateString.nanoseconds / 1000000);
        const formattedDate = date.toLocaleString(); // Format the date as needed
        return formattedDate
    };
    
    const handleCopyEmail = (email) => {
        navigator.clipboard.writeText(email.target.innerText);
        Swal.fire({
            title: 'Copied!',
            text: `${email.target.innerText} has been copied to clipboard.`,
            icon: 'success',
            confirmButtonText: 'Ok'
        });
    };

    return (
        <div className='user-card'>
            <div className="top">
                <div className="left">
                    <img src={props.img} alt='avatar' />
                </div>
                <div className="right">
                    {props.requesterName && <h3 className='name'>{props.requesterName}</h3>}
                    {props.email && <h5 className='username' onClick={handleCopyEmail}>{props.email}</h5> }
                </div>
            </div>
            <div className="bottom">
                {
                    props.type === 'pr' &&
                    <>
                        <p className='time-created'><FontAwesomeIcon icon={faClock} />&nbsp;{formatDateString(props.requestedTime)}</p>
                        <div className={`pr-status ${props.prStatus === 'Merged' ? 'merged' : props.prStatus === 'Pending' ? 'pending' : props.prStatus === 'Declined' ? 'denied' : ''}`}>
                            {props.prStatus === 'Merged' ? <FontAwesomeIcon icon={faCheckCircle} />
                                : props.prStatus === 'Pending' ? <FontAwesomeIcon icon={faClock} />
                                    : <FontAwesomeIcon icon={faTimesCircle} />
                            }&nbsp;&nbsp;&nbsp;
                            {props.prStatus}
                        </div>
                        <p>Repo Name: {props.repoName}</p>
                        <p>PR ID: {props.prId}</p>
                        <p>Title:  {props.title}</p>
                        <a href={props.url} className='view-btn'>View Details</a>
                    </>
                }
                {
                    props.type === 'user' &&
                    <>
                        <p className='time-created'>Total no of pull requests: {props.totalPR}</p>
                        <p className='pr-status merged'>Merged pull requests: {props.prMerged}</p>
                        <p className='pr-status pending'>Pending pull requests: {props.prPending}</p>
                        <Link to={`/dashboard/user/${props.requesterName}`} className='view-btn'>View Details</Link>
                    </>
                }
            </div>
        </div>
    )
}

export default UserCard