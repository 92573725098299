import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyContext } from '../pages/Main';
import { faArrowRight, faPlus, faSearch, faUser } from '@fortawesome/free-solid-svg-icons'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';

const Projects = () => {

    const { projectData, userData } = useContext(MyContext);

    const [searchTerm, setSearchTerm] = useState('');
    const handleBadge = (e) => {
        setSearchTerm(e.target.value);
    };

    const searchedProjects = projectData.filter(project =>
        project.idea.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const ProjectList = ({ title, domain, tech, owner, id }) => {
        return (
            <div className='project-list'>
                <p style={{ width: '45%' }}>{title}</p>
                <p style={{ width: '20%' }}>{domain}</p>
                <p style={{ width: '20%' }}>{tech}</p>
                <p style={{ width: '15%' }}>{owner}</p>
                <Link to={`${id}`}>
                    <button className="dashboard-btn"><FontAwesomeIcon icon={faArrowRight} />&nbsp;Explore  </button>
                </Link>
            </div>
        )
    }

    const ProjectGrid = ({ title, domain, tech, owner, id }) => {
        return (
                <div className="project-grid">
                    <p className="project-domain">{domain}</p>
                    <p className="project-title">{title}</p>
                    <p className="project-tech">{tech}</p>
                    <p className="project-owner"><FontAwesomeIcon icon={faUser} /> &nbsp;{owner}</p>
                    <Link to={`${id}`} className='project-link'>
                        <button className="dashboard-btn">
                            <FontAwesomeIcon icon={faArrowRight} />&nbsp;Explore
                        </button>
                    </Link>
                </div>
        );
    };
    

    return (
        <div className="all-projects">
            <h1 className="title">Project Ideas <Link to={'add-project'} className='add-project-idea'><FontAwesomeIcon icon={faPlus} /> &nbsp;<span>Add Project Idea</span></Link></h1>
            <label htmlFor="repo" className='dashboard-search'>
                <FontAwesomeIcon icon={faSearch} />
                <input type="text" placeholder='Search with project title' id='repo' onChange={handleBadge} />
            </label>
            <div className="projects-list">
                <div className='project-list title'>
                    <p style={{ width: '45%' }}>Title </p>
                    <p style={{ width: '20%' }}>Domain</p>
                    <p style={{ width: '20%' }}>Tech Stack</p>
                    <p style={{ width: '15%' }}>Owner</p>
                    <p className='view-project-list-title'>View details</p>
                </div>
                {searchedProjects.map(project => (
                    <ProjectList
                        key={project.id}
                        title={project.idea}
                        domain={project.category}
                        tech={project.techStack}
                        owner={project.user}
                        id={project.id}
                    />
                ))}
            </div>
            <div className="projects-grid">
                {searchedProjects.map(project => (
                    <ProjectGrid
                        key={project.id}
                        title={project.idea}
                        domain={project.category}
                        tech={project.techStack}
                        owner={project.user}
                        id={project.id}
                    />
                ))}
            </div>
        </div>
    )
}

export default Projects;
