import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const BadgeGrid = ({ id, username, badgeName, assignedOn, image }) => {

    const navigate = useNavigate()
    
    const handleOpenBadge = () => {
        navigate(`/soc-view-badge/${id}`)
  }
  

  const formatDate = (dateString) => {
    const date = new Date(dateString.seconds * 1000 + dateString.nanoseconds / 1000000);
    const formattedDate = date.toLocaleString(); // Format the date as needed
    return formattedDate
  };

    return (
        <div className="badge-grid" onClick={handleOpenBadge}>
            <p><span className="name">{username}</span> Earned 
                <img src="https://th.bing.com/th/id/R.844bf2dc1edb7f4472f11b5b72f5fe11?rik=BUGpZKguU0Gj7g&riu=http%3a%2f%2fwww.pngmart.com%2ffiles%2f7%2fReward-PNG-Transparent-Image.png&ehk=ZmNjTivB7PYd3n9HiIBnJ3cP4BEfA6UixUsi0klIiuc%3d&risl=&pid=ImgRaw&r=0" alt="https://th.bing.com/th/id/R.844bf2dc1edb7f4472f11b5b72f5fe11?rik=BUGpZKguU0Gj7g&riu=http%3a%2f%2fwww.pngmart.com%2ffiles%2f7%2fReward-PNG-Transparent-Image.png&ehk=ZmNjTivB7PYd3n9HiIBnJ3cP4BEfA6UixUsi0klIiuc%3d&risl=&pid=ImgRaw&r=0" className='badge-reward' />
            </p>
            <div className="image-box">
                <img src={image} alt={image} />
            </div>
            <p className='badge-name'>{badgeName} Badge</p>
            <span className='time'><FontAwesomeIcon icon={faCircle} className='circle'/>{formatDate(assignedOn)}</span>
        </div>
    )
}

export default BadgeGrid