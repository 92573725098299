import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState } from 'react'
import BadgeGrid from '../components/BadgeGrid';
import { MyContext } from './Main';

const AllBadges = () => {

    const { badgeData } = useContext(MyContext);

    const [searchTerm, setSearchTerm] = useState('');
    const handleBadge = (e) => {
        setSearchTerm(e.target.value);
    };

    const searchedRepos = badgeData.filter(badge =>
        badge.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="all-badges">
            <h1 className="title">All Badges</h1>
            <label htmlFor="repo" className='dashboard-search'>
                <FontAwesomeIcon icon={faSearch} />
                <input type="text" placeholder='Search with github username' id='repo' onChange={handleBadge} />
            </label>
            <div className="badges-container">
                {searchedRepos ? searchedRepos.map((badge, index) => (
                    <BadgeGrid key={index} id={badge.id} username={badge.username} badgeName={badge.badgeName} assignedOn={badge.assignedOn} image={badge.image} />
                ))
                : <h3>No one earned a badge</h3>
            }
            </div>
        </div>
    )
}

export default AllBadges