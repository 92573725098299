import React, { useContext, useState } from 'react';
import { MyContext } from '../pages/Main';
import Swal from 'sweetalert2';


const AddProjectForm = () => {
    
    const { addProjectIdea } = useContext(MyContext);

    const [loading, setLoading] = useState(false);


    const [formData, setFormData] = useState({
        idea: '',
        moreInfo: '',
        techStack: '',
        category: '',
        executionTime: '',
        githubLink: '',
        playStoreLink: '',
        appStoreLink: '',
        currentStatus: '',
        remarks: '',
        webLink: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        // Filter out fields with empty values
        const filteredData = Object.keys(formData).reduce((acc, key) => {
            if (formData[key]) {
                acc[key] = formData[key];
            }
            return acc;
        }, {});

        try {
            await addProjectIdea({...filteredData, ok:0})
            console.log('Project added successfully');
            setFormData({
                idea: '',
                moreInfo: '',
                techStack: '',
                category: '',
                executionTime: '',
                githubLink: '',
                playStoreLink: '',
                appStoreLink: '',
                currentStatus: '',
                remarks: '',
                webLink: '',
            });
            Swal.fire({
                title: 'Under Review',
                text: 'Will be public once reviewed!',
                icon: 'success',
                confirmButtonText: 'Done!'
            });
        } catch (error) {
            console.error('Error adding document:', error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: '<Link to="contact-us">Contact Us?</Link>'
            });
        }
        setLoading(false)
    };

    return (
        <div className="contact-form project-form">
            {loading && <div className="loading-project">
                <div className="loading"></div>
            </div>}
            <h2>Project Submission Form</h2>
            <form className='contact-me-form' onSubmit={handleSubmit}>
                <div className="part">
                    <label>What's your idea?</label>
                    <input
                        className='contact-input'
                        type="text"
                        name="idea"
                        placeholder="Briefly describe your project idea"
                        value={formData.idea}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="part">
                    <label>You want to tell us more about it?</label>
                    <textarea
                        className='contact-input'
                        name="moreInfo"
                        placeholder="Provide more details about your idea"
                        value={formData.moreInfo}
                        onChange={handleChange}
                    />
                </div>
                <div className="part">
                    <label>Anything else to share, like execution plan, tech stack, etc.?</label>
                    <textarea
                        className='contact-input'
                        name="techStack"
                        placeholder="Execution plan, technologies used, etc."
                        value={formData.techStack}
                        onChange={handleChange}
                    />
                </div>
                <div className="part">
                    <label>Category</label>
                    <select
                        className='contact-input'
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                    >
                        <option value="">Select a category</option>
                        <option value="App">App</option>
                        <option value="Game">Game</option>
                        <option value="Website">Web</option>
                        <option value="Bot">Bot</option>
                        <option value="Utility">Utility</option>
                        <option value="Server">Server</option>
                        <option value="Blockchain">Blockchain</option>
                        <option value="AI">Artificial Intelligence</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className="part">
                    <label>How much approx time to execute the idea?</label>
                    <select
                        className='contact-input'
                        name="executionTime"
                        value={formData.executionTime}
                        onChange={handleChange}
                    >
                        <option value="">Select an execution time</option>
                        <option value="small">Small (1 - 2 weeks)</option>
                        <option value="medium">Medium (1 - 2 months)</option>
                        <option value="large">Large (3 - 6 months)</option>
                        <option value="extraLarge">Extra Large (More than 6 months)</option>
                    </select>
                </div>
                <div className="part">
                    <label>GitHub Link</label>
                    <input
                        className='contact-input'
                        type="url"
                        name="githubLink"
                        placeholder="Link to your GitHub repository"
                        value={formData.githubLink}
                        onChange={handleChange}
                    />
                </div>
                <div className="part">
                    <label>Play Store Link</label>
                    <input
                        className='contact-input'
                        type="url"
                        name="playStoreLink"
                        placeholder="Link to the Play Store (if applicable)"
                        value={formData.playStoreLink}
                        onChange={handleChange}
                    />
                </div>
                <div className="part">
                    <label>App Store Link</label>
                    <input
                        className='contact-input'
                        type="url"
                        name="appStoreLink"
                        placeholder="Link to the App Store (if applicable)"
                        value={formData.appStoreLink}
                        onChange={handleChange}
                    />
                </div>
                <div className="part">
                    <label>Project Current Status</label>
                    <input
                        className='contact-input'
                        type="text"
                        name="currentStatus"
                        placeholder="Current status of your project"
                        value={formData.currentStatus}
                        onChange={handleChange}
                    />
                </div>
                <div className="part">
                    <label>Remarks</label>
                    <textarea
                        className='contact-input'
                        name="remarks"
                        placeholder="Any additional remarks or comments"
                        value={formData.remarks}
                        onChange={handleChange}
                    />
                </div>
                <div className="part">
                    <label>Web Link</label>
                    <input
                        className='contact-input'
                        type="url"
                        name="webLink"
                        placeholder="Link to your project's website (if applicable)"
                        value={formData.webLink}
                        onChange={handleChange}
                    />
                </div>
                <div className="part">
                </div>
                    <button type="submit" style={{cursor: 'pointer'}} className="submit-btn">Submit</button>
            </form>
        </div>
    );
};

export default AddProjectForm;
