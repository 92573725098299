import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import logo from '../images/codesapiens.png';
import { MyContext } from '../pages/Main';

const BadgePage = () => {
    const { id } = useParams();
    const { userData, isAuth, email } = useContext(MyContext);
    const [badgeData, setBadgeData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const formatDateString = (dateString) => {
        const date = new Date(dateString.seconds * 1000 + dateString.nanoseconds / 1000000);
        const formattedDate = date.toLocaleString(); // Format the date as needed
        return formattedDate
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = doc(db, 'userbadges', id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setBadgeData(docSnap.data());
                } else {
                    setError(true);
                }
            } catch (error) {
                console.error("Error fetching badge data: ", error);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (loading) {
        return <h1 style={{width:'100%', height:'80vh', display:'flex', alignItems: 'center', justifyContent: 'center'}}>Loading...</h1>;
    }

    if (error) {
        return <h1 style={{width:'100%', height:'80vh', display:'flex', alignItems: 'center', justifyContent: 'center'}}>Invalid Data!</h1>;
    }

    const url = `${window.location.origin}/soc-view-badge/${id}`;
    
    const message = `🎉 Thrilled to announce that I’ve earned a badge for my contributions during the Code Sapiens - Summer of Code (CSSOC) event! 🌟
Participating in CSSOC has been an incredible journey, filled with learning, collaboration, and growth. This badge is a testament to the hard work and dedication I’ve put into various projects throughout the event. 
A big thank you to the CSSOC organizers, mentors, and my fellow participants for making this experience truly memorable. Looking forward to earning more badges and continuing to contribute to the community and expand my skills even further! 💪🚀`;

    return (
        <div className="badge-page">
            <div className="verify-note">
                <a href={`https://github.com/${badgeData.username}`}>@{badgeData.username}</a> was awarded this badge on {formatDateString(badgeData.assignedOn)}.<br />
                The unique Badge ID is {id}.
            </div>
            <div className="dets">
                <div className="right">
                    <img src={badgeData.image} alt={badgeData.badgeName} />
                </div>
                <div className="left">
                    <div className="title">
                        <span className="logo"><img src={logo} alt="CodeSapiens Logo" /></span>
                        &nbsp;<p> CodeSapiens </p>&nbsp; <div className="verified">Verified</div>
                    </div>
                    <h1 className="badge-name">{badgeData.badgeName} Badge</h1>
                    <div className="hashs"><span className="hash">CodeSapiens</span><span className="hash">Summer Of Code</span></div>
                    <h2 className="earning-criteria">Earning criteria</h2>
                    <p>To earn this badge you must be a <br />Code Sapiens member</p>
                        {isAuth && userData!=='' && userData===badgeData.username && email !=='' && <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}&text=${message}`} className="share-now">
                            <FontAwesomeIcon icon={faLinkedin} />&nbsp; Share To LinkedIn
                        </a>}
                </div>
            </div>
        </div>
    );
};

export default BadgePage;
