import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const ProjectDetail = () => {
    const { id } = useParams();
    const [project, setProject] = useState(null);

    useEffect(() => {
        const fetchProject = async () => {
            const docRef = doc(db, 'projectIdeas', id); // Adjust the collection name as needed
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setProject(docSnap.data());
            } else {
                console.log('No such document!');
            }
        };

        fetchProject();
    }, [id]);

    if (!project) {
        return <p>Loading...</p>;
    }

    return (
        <div className="project-detail">
            {project.idea && (
                <div className="project-header">
                    <h1 className="title">{project.idea}</h1>
                </div>
            )}
            {project.user &&
                <p style={{width:'90%', textAlign: 'end'}}>-{project.user}</p>
            }
            <div className="project-info">
                {project.category && (
                    <div className="info-block">
                        <h2>Category:</h2>
                        <p>{project.category}</p>
                    </div>
                )}
                {project.techStack && (
                    <div className="info-block">
                        <h2>Tech Stack:</h2>
                        <p>{project.techStack}</p>
                    </div>
                )}
                {project.executionTime && (
                    <div className="info-block">
                        <h2>Execution Time:</h2>
                        <p>{project.executionTime}</p>
                    </div>
                )}
                {project.githubLink && (
                    <div className="info-block">
                        <h2>GitHub Link:</h2>
                        <p>
                            <a href={project.githubLink} target="_blank" rel="noopener noreferrer">
                                {project.githubLink}
                            </a>
                        </p>
                    </div>
                )}
                {project.playStoreLink && (
                    <div className="info-block">
                        <h2>Play Store Link:</h2>
                        <p>
                            <a href={project.playStoreLink} target="_blank" rel="noopener noreferrer">
                                {project.playStoreLink}
                            </a>
                        </p>
                    </div>
                )}
                {project.appStoreLink && (
                    <div className="info-block">
                        <h2>App Store Link:</h2>
                        <p>
                            <a href={project.appStoreLink} target="_blank" rel="noopener noreferrer">
                                {project.appStoreLink}
                            </a>
                        </p>
                    </div>
                )}
                {project.webLink && (
                    <div className="info-block">
                        <h2>Web Link:</h2>
                        <p>
                            <a href={project.webLink} target="_blank" rel="noopener noreferrer">
                                {project.webLink}
                            </a>
                        </p>
                    </div>
                )}
                {project.currentStatus && (
                    <div className="info-block">
                        <h2>Current Status:</h2>
                        <p>{project.currentStatus}</p>
                    </div>
                )}
                {project.remarks && (
                    <div className="info-block">
                        <h2>Remarks:</h2>
                        <p>{project.remarks}</p>
                    </div>
                )}
            </div>
            <div className="description">
                <h2>Description:</h2>
                <p>{project.moreInfo}</p>
            </div>
        </div>
    );
};

export default ProjectDetail;
