import { faTimes, faHome, faCodePullRequest, faTrophy, faChartPie, faContactCard, faArrowRight, faArrowRightFromBracket, faBox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { MyContext } from '../pages/Main'
import GithubLogin from './GithubLogin';
import { auth } from '../firebaseConfig';
import GmailLogin from './GmailLogin';

const Sidebar = ({ isAside, setIsAside }) => {

    const { isAuth, setIsAuth, userData, setEmail, setUserData, email } = useContext(MyContext)

    const navigate = useNavigate();

    const handleAside = () => {
        setIsAside(false);
    }

    const handleLogout = async () => {
        try {
            await auth.signOut();
            setIsAside(false)
            setEmail(null)
            setUserData(null)
            setIsAuth(false)
            localStorage.removeItem('e')
            localStorage.removeItem('u')
            navigate('/')
        } catch (error) {
            console.log(error.message)
        }
    }

    const LoginButton = () => {
        if (isAuth && userData !== null && email !== null) {
            return <div className="login-home">
                <Link to='/dashboard'>
                    <FontAwesomeIcon icon={faArrowRight} /> Go To Dashboard
                </Link>
            </div>
        }
        if (isAuth === false && userData === null && email === null) {
            return <GmailLogin />
        }
        if (isAuth === false && userData === null && email !== null) {
            return <GithubLogin />
        }
        return <h3>Loading...</h3>
    }

    return (
        <aside className={isAside ? 'open' : 'closed'}>
            <div className="side-bar-close" onClick={handleAside}>
                <FontAwesomeIcon icon={faTimes} className='aside-close' />
            </div>
            <ul className="nav">
                <li onClick={handleAside}><NavLink to='/'><FontAwesomeIcon icon={faHome} />&nbsp;Home</NavLink></li>
                {isAuth ?
                    <>
                        <li onClick={handleAside}><NavLink to='/dashboard'><FontAwesomeIcon icon={faChartPie} />&nbsp;Dashboard</NavLink></li>
                        <li onClick={handleAside}><NavLink to='/projects'><FontAwesomeIcon icon={faBox} />&nbsp;Project Ideas</NavLink></li>
                        <li onClick={handleAside}><NavLink to='/leaderboard'><FontAwesomeIcon icon={faTrophy} />&nbsp;Leaderboard</NavLink></li>
                        <li onClick={handleAside}><NavLink to={`/profile/${userData}`}><FontAwesomeIcon icon={faCodePullRequest} />&nbsp;My Profile</NavLink></li>
                        <li onClick={handleAside}><NavLink to='/contact-us'><FontAwesomeIcon icon={faContactCard} />&nbsp;Contact us</NavLink></li>
                    </>
                    :
                    <>
                        <li onClick={handleAside}><NavLink to='/projects'><FontAwesomeIcon icon={faBox} />&nbsp;Project Ideas</NavLink></li>
                        <li onClick={handleAside}><NavLink to='/contact-us'><FontAwesomeIcon icon={faContactCard} />&nbsp;Contact us</NavLink></li>
                        <li onClick={handleAside} className='login-side-bar'><LoginButton /></li>
                    </>
                }
                {(isAuth || email !== null) && <li onClick={handleLogout} className='login-side-bar'><Link to={'/'}><FontAwesomeIcon icon={faArrowRightFromBracket} />&nbsp;Logout</Link></li>}
            </ul>
        </aside>
    );
}

export default Sidebar;
