import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { MyContext } from './Main';

const Leaderboard = () => {
  
  const { leaderboardData, userData, leaderboardLoading } = useContext(MyContext);

  return (
    <div className="leaderboard">
      <div className="title">
        <h1>Summer Of Code Leaderboard</h1>
      </div>
      <div className="leaderboard-table">
        {!leaderboardLoading ?
          <table border={'1px'}>
      <thead>
        <tr>
          <th>#</th>
          <th>Username</th>
          <th>Points</th>
          <th>View User</th>
        </tr>
          </thead>
          <tbody>
          {leaderboardData.map((user, index) => (
              <tr key={user.id} className={user.username === userData ? 'my-rank': ''}>
                <td>{index + 1}</td>
                <td className='username'>
                {user.username} &nbsp;
                <span className={index === 0 ? 'first' : index === 1 ? 'second' : index === 2 ? 'third' : 'non-top-3'}>
                  {index === 0 ? '1st' : index === 1 ? '2nd' : index === 2 && '3rd'}
                </span>&nbsp;
                {user.username === userData && '(You)'}
                </td>
                <td>{user.points}</td>
                <td>
                  <Link to={`/dashboard/user/${user.username}`}>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
          :
          <div className='loading-animation'><div className='loader'></div></div>
    }
      </div>
    </div>
  )
}

export default Leaderboard