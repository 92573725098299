import { faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Footer = () => {
  return (
    <footer>
      <p>©️ 2024 CodeSapiens. All Rights Reserved.</p>
      <div className="footer-links">
        <a href="https://www.instagram.com/codesapiens.in"><FontAwesomeIcon icon={faInstagram} /> Instagram</a>
        <a href="https://www.linkedin.com/company/codesapiens-community/"><FontAwesomeIcon icon={faLinkedin} /> Linked In</a>
        <a href="https://www.youtube.com/@CodeSapiens-in"><FontAwesomeIcon icon={faYoutube} /> Youtube</a>
      </div>
    </footer>
  )
}

export default Footer