import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import EarthCanvas from '../assets/canvas/Earth';
import axios from 'axios';

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://us-central1-codesapiensbot.cloudfunctions.net/sendEmail', formData);
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    }
  };

  return (
    <div className='contact-me'>
      <div className="title">
        <h1><span className="green">Contact Us!!</span></h1>
      </div>
      <div className="contact-body">
        <div className="left">
          <EarthCanvas />
        </div>
        <div className="contact-form">
          <form className='contact-me-form' onSubmit={handleSubmit}>
            <div className="part">
              <label htmlFor="name">Name </label>
              <input type="text" id='name' placeholder="What's your name?" name='name' className='contact-input' onChange={handleChange} />
            </div>
            <div className="part">
              <label htmlFor="email">Email id </label>
              <input type="text" id='email' placeholder="What's your email?" name='email' className='contact-input' onChange={handleChange} />
            </div>
            <div className="part">
              <label htmlFor="subject">Subject</label>
              <input type="text" id='subject' placeholder='What is the Subject?' name='subject' className='contact-input' onChange={handleChange} />
            </div>
            <div className="part">
              <label htmlFor="message">Message </label>
              <textarea id='message' placeholder='What do you wanna say?' name='message' className='contact-input' onChange={handleChange} />
            </div>
            <button type="submit">
              <FontAwesomeIcon icon={faPaperPlane} />
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
