import React, { useContext, useEffect, useState } from 'react';
import UserCard from '../components/UserCard';
import { useParams } from 'react-router-dom';
import { MyContext } from './Main';
import BadgeGrid from '../components/BadgeGrid';

const Profile = () => {
  const { userData, email, prData, badgeData, leaderboardData } = useContext(MyContext);
  const [userRepos, setUserRepos] = useState([]);
  const [myBadges, setBadges] = useState([]);
  const params = useParams();
  const [rank, setRank] = useState('Unranked');

  useEffect(() => {
    const user_repo_data = params.userName ? prData.filter(item => item.requesterName == params.userName) : [];
    const badges_data = badgeData ? badgeData.filter(item => item.username == userData) : [];
    setUserRepos(user_repo_data);
    setBadges(badges_data);
  }, [badgeData, prData, params.userName, userData]);

  const totalReq = userRepos ? userRepos.length : 0;
  const mergedReq = userRepos ? userRepos.filter(pr => pr.prStatus === 'Merged').length : 0;
  const pendingReq = userRepos ? userRepos.filter(pr => pr.prStatus === 'Pending').length : 0;

  useEffect(() => {
    const userRank = leaderboardData.find(data => data.username === userData);
    if (userRank) {
      setRank(userRank.rank);
    } else {
      setRank('Unranked');
    }
  },[leaderboardData])

  return (
    <>
      {params.userName === userData ? (
        <div className='profile'>
          <div className="dets">
            <div className='user-card'>
              <div className="top">
                <div className="left">
                  <img src={`https://github.com/${userData}.png`} alt='avatar' />
                </div>
                <div className="right">
                  {userData && <h3 className='name'>{userData}</h3>}
                  {email && <h5 className='username'>{email}</h5>}
                  {rank && <h5><span className="rank">Leaderboard Rank:</span> { rank }</h5>}
                </div>
              </div>
              <div className="bottom">
                <p className='time-created'>Total no of requests: {totalReq}</p>
                <p className='pr-status merged'>Merged requests: {mergedReq}</p>
                <p className='pr-status pending'>Pending requests: {pendingReq}</p>
              </div>
            </div>
          </div>
          <div className="pr-n-badges">
            <div className="my-pull-reqs">
              <h2 className="title">Pull Requests</h2>
              {userRepos.length !== 0 ? (
                <div className="all-repos">
                  {userRepos.map((pr, index) => (
                    <UserCard
                      key={index}
                      type="pr"
                      img={`https://github.com/${pr.requesterName}.png`}
                      repoName={pr.repoName}
                      prId={pr.prId}
                      title={pr.title}
                      name={pr.name}
                      requesterName={pr.requesterName}
                      requestedTime={pr.requestedTime}
                      prStatus={pr.prStatus}
                      url={pr.htmlUrl}
                    />
                  ))}
                </div>
              ) : (
                <h4>You have not contributed to any repository</h4>
                // <h4>This user hasn't </h4>
              )}
            </div>
            <div className="my-earned-badges">
              <h2 className="title">Badges</h2>
              {myBadges.length !== 0 ? (
                <div className="badges-container">
                  {myBadges.map((badge, index) => (
                    <BadgeGrid
                      key={index}
                      id={badge.id}
                      username={badge.username}
                      badgeName={badge.badgeName}
                      assignedOn={badge.assignedOn}
                      image={badge.image}
                    />
                  ))}
                </div>
              ) : (
                <h4>You don't have any badges</h4>
              )}
            </div>
          </div>
        </div>
      ) : (
        <h1>Unauthorized User!</h1>
      )}
    </>
  );
};

export default Profile;
