import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Badge = ({id, username, badgeName, assignedOn, image }) => {
    const navigate = useNavigate()

    const handleOpenBadge = () => {
        navigate(`/soc-view-badge/${id}`)
    }

    const formatDate = (timestamp) => {
        const now = new Date();
        const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
        const diffInSeconds = Math.floor((now - date) / 1000);
        
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        const diffInMonths = Math.floor(diffInDays / 30);
      
        if (diffInMonths > 1) {
          return "more than a month ago";
        } else if (diffInMonths === 1) {
          return "a month ago";
        } else if (diffInDays > 1) {
          return `${diffInDays} days ago`;
        } else if (diffInDays === 1) {
          return "1 day ago";
        } else if (diffInHours > 1) {
          return `${diffInHours} hours ago`;
        } else if (diffInHours === 1) {
          return "1 hour ago";
        } else if (diffInMinutes > 1) {
          return `${diffInMinutes} minutes ago`;
        } else if (diffInMinutes === 1) {
          return "1 minute ago";
        } else {
          return "just now";
        }
      };

    return (
        <div className="badge" onClick={handleOpenBadge}>
            <div className="left">
                <img src={image} alt={image} />
            </div>
            <div className="right">
                <p><a href={`https://github.com/${username}`}>@{username}</a> has earned a <b>{badgeName} </b>badge!</p>
                <p className='awarded-by'>awarded by CodeSapiens</p>
                <p className="awarded-time">
                    <FontAwesomeIcon icon={faClock} /> {formatDate(assignedOn)}
                </p>
            </div>
        </div>
    )
}

export default Badge


{/* <div className="badge" onClick={handleOpenBadge}>
            <p><span className="name">{username}</span> Earned 
                <img src="https://th.bing.com/th/id/R.844bf2dc1edb7f4472f11b5b72f5fe11?rik=BUGpZKguU0Gj7g&riu=http%3a%2f%2fwww.pngmart.com%2ffiles%2f7%2fReward-PNG-Transparent-Image.png&ehk=ZmNjTivB7PYd3n9HiIBnJ3cP4BEfA6UixUsi0klIiuc%3d&risl=&pid=ImgRaw&r=0" alt="https://th.bing.com/th/id/R.844bf2dc1edb7f4472f11b5b72f5fe11?rik=BUGpZKguU0Gj7g&riu=http%3a%2f%2fwww.pngmart.com%2ffiles%2f7%2fReward-PNG-Transparent-Image.png&ehk=ZmNjTivB7PYd3n9HiIBnJ3cP4BEfA6UixUsi0klIiuc%3d&risl=&pid=ImgRaw&r=0" className='badge-reward' />
            </p>
            <div className="image-box">
                <img src={image} alt={image} />
            </div>
            <p className='badge-name'>{badgeName}</p>
            <span className='time'><FontAwesomeIcon icon={faCircle} className='circle'/>{time}</span>
            <div className="tr-cover">
            </div>
        </div> */}