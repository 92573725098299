import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { auth, googleProvider } from '../firebaseConfig';
import { signInWithPopup, signOut } from 'firebase/auth';
import Swal from 'sweetalert2';
import React, { useContext } from 'react'
import { MyContext } from '../pages/Main';
import { db } from '../firebaseConfig';
import { collection, getDocs, where, query } from 'firebase/firestore';

const GmailLogin = () => {

    const { setEmail, setUserData, setIsAuth } = useContext(MyContext)

    const signInWithGoogle = async () => {
        try {
            googleProvider.addScope('email');
            googleProvider.addScope('profile');
            const result = await signInWithPopup(auth, googleProvider);
            const userDataEmail = result.user.email;
            const userProviderEmail = result.user.providerData[0].email
            const userEmail = userDataEmail === null ? userProviderEmail : userDataEmail
            if (userEmail) {
                const authorizedUser = await getDocs(query(collection(db, 'soc-users'), where('email', '==', userEmail)));
                console.log(authorizedUser);
                if (!authorizedUser.empty) {
                    const userdata_doc = authorizedUser.docs[0].data();
                    const username = userdata_doc.username;
                    setEmail(userEmail);
                    console.log('Email Registeration successfull', userEmail);
                    if (username) {
                        console.log(username);
                        setUserData(username);
                        setIsAuth(true);
                        Swal.fire({
                            title: 'Hey There',
                            text: 'Shall we Rock?',
                            icon: 'success',
                            confirmButtonText: 'Yeah!'
                        });
                    } else {
                        Swal.fire({
                            title: 'Welcome',
                            text: 'Now Register Your Github Once',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        });
                    }
                } else {
                    await signOut(auth);
                    Swal.fire({
                        title: 'Unauthorized',
                        text: 'You have not registered for the event.',
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                }
            }
            else {
                Swal.fire({
                    title: 'No email Retrieved',
                    text: 'Contact Coordinators',
                    icon: 'Failure',
                });
            }

        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: '<Link to="contact-us">Contact Us?</Link>'
            });
        }
    };


    return (
        <div className='google-btn' onClick={signInWithGoogle}>
            <FontAwesomeIcon icon={faGoogle} /> Sign in via Gmail
        </div>
    );
}

export default GmailLogin